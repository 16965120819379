






/* eslint-disable quote-props, @typescript-eslint/no-explicit-any */
import { defineComponent, onMounted } from '@vue/composition-api'
import { mapGetters } from '@/store/utils'
import { getContentPage, push } from '@/inc/utils'
import TopicsSingle from './topics/Single.vue'

export default defineComponent({
  name: 'topics',
  components: {
    TopicsSingle,
  },
  setup(props, ctx) {
    const obj = mapGetters(ctx, ['content', ['template'], 'tracking'])

    onMounted(() => {
      // DGTM 5.1.2
      push(
        getContentPage(obj.template.value, {
          title: obj.content.value.hero.title,
          'content-topic': obj.content.value.hero.title,
          'content-type': 'topic',
          'content-public': 1,
        })
      )
    })

    return {
      ...obj,
    }
  },
})
